import { ensureMediaPermissions } from '../utils/index';
import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { initialSettings, Settings, SettingsAction, settingsReducer } from './settings/settingsReducer';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string): Promise<string>;
  user?: null | { displayName?: string; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  validateMeetingCode(mettingCode: string): Promise<boolean>;
  isValidMeetingCode: boolean;
  setIsValidMeetingCode(isValid: boolean): void;
  hasMediaDevicesAccess: boolean;
  setHasMediaDevicesAccess(isAllowed:boolean):void; 
  meetingCode: string;
  setMeetingCode(code: string): void;
  shouldShowRejoinMeeting: boolean;
  setShouldShowRejoinMeeting(show: boolean): void;
  shouldShowRatingModal: boolean;
  setShouldShowRatingModal(show: boolean): void;
  shouldShowDeviceSelection: boolean;
  setShouldShowDeviceSelection(show: boolean): void;
  isMeetingRefresh: boolean;
  setIsMeetingRefresh(refresh: boolean): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [isValidMeetingCode, setIsValidMeetingCode] = useState(false);
  const [meetingCode, setMeetingCode] = useState('');
  const [hasMediaDevicesAccess, setHasMediaDevicesAccess] = useState(false);
  const [shouldShowRejoinMeeting, setShouldShowRejoinMeeting] = useState(false);
  const [shouldShowRatingModal, setShouldShowRatingModal] = useState(false);
  const [shouldShowDeviceSelection, setShouldShowDeviceSelection] = useState(true);
  const [isMeetingRefresh, setIsMeetingRefresh] = useState(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    isValidMeetingCode,
    meetingCode,
    setMeetingCode,
    hasMediaDevicesAccess,
    setHasMediaDevicesAccess,
    shouldShowRejoinMeeting,
    setShouldShowRejoinMeeting,
    shouldShowRatingModal,
    setShouldShowRatingModal,
    shouldShowDeviceSelection,
    setShouldShowDeviceSelection,
    isMeetingRefresh,
    setIsMeetingRefresh
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (roomName) => {
      const headers = new window.Headers();
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
      // const params = new window.URLSearchParams(roomName);
      return ensureMediaPermissions().then(() =>
        fetch(`${endpoint}?meetingCode=${roomName}`, { headers }).then(res => res.text()));
    },
    validateMeetingCode: async (meetCode): Promise<boolean> => {
      const headers = new window.Headers();
      const endpoint = process.env.REACT_APP_VALIDATE_TOKEN_ENDPOINT;

      const response = await fetch(`${endpoint}?meetingCode=${meetCode}`, { headers });

      return response.ok;
    }
  };


  const getToken: StateContextType['getToken'] = (room) => {
    setIsFetching(true);
    return contextValue
      .getToken(room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  }

  const validateMeetingCode: StateContextType['validateMeetingCode'] = (meetCode) => {
    setIsFetching(true);
    return contextValue
      .validateMeetingCode(meetCode)
      .then(res => {
        setIsFetching(false);
        setIsValidMeetingCode(res);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        setIsValidMeetingCode(false);
        return Promise.reject(err);
      });
  }

  return <StateContext.Provider value={{ ...contextValue, getToken, validateMeetingCode }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
