import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2000,
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            marginBottom: '16px',
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '16px',
            maxWidth: '460px',
            textAlign: 'center'
        },
        textwithmargin: {
            marginTop: '16px',
        },
        footertext: {
            marginBottom: '16px',
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '12px',
            marginTop: '24px'
        },
        linktext: {
            color: '#ffffff',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        headertext: {
            marginBottom: '24px',
            fontSize: '24px',
            color: '#ffffff'
        },
        progress: {
            marginLeft: '1em',
        },
    })
);

export default function RejoinMeeting() {
    const classes = useStyles();
    const { getToken, isFetching, meetingCode, setShouldShowRejoinMeeting, setShouldShowRatingModal } = useAppState();
    const { isConnecting, connect, isAcquiringLocalTracks, getLocalAudioTrack } = useVideoContext();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

    const handleRejoin = () => {
        getToken(meetingCode).then(token => connect(token));
        // enable video if disabled
        if (!isVideoEnabled) {
            toggleVideoEnabled();
        }
        // fetch audio track and enable it
        getLocalAudioTrack().then(() => {
            if (!isAudioEnabled) {
                toggleAudioEnabled();
            }

            setShouldShowRejoinMeeting(false);
            setShouldShowRatingModal(false);
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.headertext}>Your Session has Ended</div>
            <div className={classes.text}>Thank you for participating in this telehealth session. If you accidentally exited the meeting or need to rejoin, click the button below.</div>
            <Button
                onClick={handleRejoin}
                color="primary"
                variant="contained"
                disabled={isAcquiringLocalTracks || isConnecting || isFetching}
            >
                {(isConnecting || isFetching) ? 'JOINING' : 'REJOIN MEETING'}
                {(isConnecting || isFetching) && <CircularProgress size={24} className={classes.progress} />}
        </Button>
            <div className={`${classes.text} ${classes.textwithmargin}`}>
                If you are finished, please close this page to ensure personal 
                health information is protected.
            </div>
            <div className={classes.footertext}>
                Disconnected or having technical problems?<br />
                <span onClick={handleRejoin} className={classes.linktext}>
                    Click here
                </span> to try again or call <a className={classes.linktext} href="tel:+18775221275">1-877-522-1275</a>.
            </div>
        </div>
    );
}
