import React, { ChangeEvent, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useAppState } from '../../state';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingSpinner: {
            marginLeft: '1em',
        }
    }));

export default function EntryScreen() {
    const classes = useStyles();
    const [ isComponentValidMeetingCode, setIsValidCode ] = useState<boolean>(true);
    const { validateMeetingCode, isFetching, meetingCode, setMeetingCode} = useAppState();
    const { URLMeetingCode } = useParams() as { 
        URLMeetingCode: string;
      };

    useEffect(() => {
        
        if (URLMeetingCode && !meetingCode) {
            validateComponentMeetingCode(URLMeetingCode)
            .then(() => 
            {
                return;
            });
        }
    // eslint-disable-next-line
    },[meetingCode]);

    const handleMeetingCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMeetingCode(event.target.value);
    };

    const handleLaunch = async () => {
        await validateComponentMeetingCode(meetingCode);
    };

    const history = useHistory();

    const validateComponentMeetingCode = async (code: string) => {
        const isValid = await validateMeetingCode(code);
        if (isValid) {
            setMeetingCode(code);
            history.push(`/meeting/${code}`);
        } else {
            setIsValidCode(isValid);
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" top="0" left="0" right="0" bottom="0" position="fixed" overflow="auto">
            <Box mt={4} mr={4} ml={4}>
                <img src={process.env.PUBLIC_URL + '/ideal-optionwhite-logo.png'} alt="IdealOption logo" width="100%" height="auto"/>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={8} mr={4} ml={4}>
                <Box display="flex" alignItems="baseline">
                    <TextField id="menu-room"
                        label="Meeting Code"
                        error={!isComponentValidMeetingCode}
                        value={meetingCode}
                        onChange={handleMeetingCodeChange}
                        margin="dense"
                        helperText={isComponentValidMeetingCode ? "" : "Invalid meeting code"} />
                    <Box m={1}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleLaunch}
                            disabled={!meetingCode || isFetching}>
                            {isFetching ? 'LAUNCHING' : 'LAUNCH'}
                            {isFetching && <CircularProgress size={24} className={classes.loadingSpinner} />}
                            </Button>
                    </Box>
                </Box>
                <Box mt={1}>
                    <Typography variant="caption">
                        To launch your Ideal Option video session, please enter your 9-digit meeting code.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}