import React from 'react';
import { Button, CardContent, Theme, Card, Typography, CircularProgress, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AudioOutputList from '../MenuBar/DeviceSelector/AudioOutputList/AudioOutputList';
import VideoInputList from '../MenuBar/DeviceSelector/VideoInputList/VideoInputList';
import AudioInputList from '../MenuBar/DeviceSelector/AudioInputList/AudioInputList'
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingSpinner: {
            marginLeft: '1em',
        },
        container: {
            overflow: 'auto',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
            '& .inputSelect': {
                width: 'calc(100% - 35px)',
            }
        },
        loaderContainer: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: "center"
        }
    })
);

export default function MeetingStartDevicesSelection() {
    const classes = useStyles();
    const { isFetching, meetingCode, getToken, setShouldShowDeviceSelection } = useAppState();
    const { isConnecting, connect, isAcquiringLocalTracks, localTracksWereAcquired } = useVideoContext();

    const joinMeeting = async () => {
        const token = await getToken(meetingCode);
        await connect(token);
        setShouldShowDeviceSelection(false);
    }

    // Don't render the selection content unless audio and video devices
    // are not acquired or acquiring is in progress
    if (!localTracksWereAcquired || isAcquiringLocalTracks) {
        return (
            <Box className={classes.loaderContainer}>
                <Typography gutterBottom color="textSecondary" variant="subtitle1">Acquiring devices...</Typography>
                <CircularProgress className={classes.loadingSpinner} />
            </Box>
        );
    }

    return (
        <Card className={classes.container}>
            <CardContent>
                <Box mb={2}>
                    <AudioInputList />
                </Box>
                <Box mb={2}>
                    <AudioOutputList />
                </Box>
                <Box mb={2}>
                    <VideoInputList />
                </Box>
                <Button variant="contained"
                    id="join-meeting-btn"
                    color="primary"
                    onClick={joinMeeting}
                    disabled={isAcquiringLocalTracks || isConnecting || isFetching}>
                    {isConnecting || isFetching ? 'JOINING' : 'JOIN MEETING'}
                    {(isConnecting || isFetching) && <CircularProgress size={24} className={classes.loadingSpinner} />}
                </Button>
            </CardContent>
        </Card>
    );
}