import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select, Button } from '@material-ui/core';
import { useAppState } from '../../../../state';
import { makeStyles } from '@material-ui/core/styles';
import { useDevices } from '../deviceHooks/deviceHooks';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    buttonPadding: {
        marginLeft: '10px',
    }
});

export default function AudioOutputList() {
    const classes = useStyles();
    const devices = useDevices();
    const { activeSinkId, setActiveSinkId } = useAppState();
    const audioOutputDevices = devices.length ? devices.filter(device => device.kind === 'audiooutput') : [];
    const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

    const [testingSound, setTestingSound] = useState(false);
    const [audio, setAudio] = useState(new Audio("/soundTest.mp3"));

    audio.loop = true;
    audio.preload = "none";

    useEffect(() => {
        testingSound ? audio.play() : audio.pause();
    },
        [testingSound]
    );

    const toggleSound = () => setTestingSound(!testingSound);

    return (
        <div className={classes.container}>
            <div className="inputSelect">
                {audioOutputDevices.length > 1 ? (
                    <FormControl fullWidth>
                        <Typography variant="h6">Speaker</Typography>
                        <Select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId}>
                            {audioOutputDevices.map(device => (
                                <MenuItem value={device.deviceId} key={device.deviceId}>
                                    {device.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <>
                        <Typography variant="h6">Speaker</Typography>
                        <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
                    </>
                )}
            </div>
            <Button className={classes.buttonPadding} variant="contained" color="primary" onClick={toggleSound}>
                {testingSound ? "STOP" : "TEST"}
            </Button>
        </div>
    );
}