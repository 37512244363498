import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room, localTracks } = useVideoContext();

  const handleEndCall = () => {
    room.disconnect();
    // stop audio and video track
    const audioTrack = localTracks.find(track => track.kind === 'audio');
    const videoTrack = localTracks.find(track => track.kind === 'video');
    audioTrack?.stop();
    videoTrack?.stop();
  };

  return (
    <Tooltip title={'End Call'} onClick={handleEndCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} color="primary">
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
