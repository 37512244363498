import React from 'react';
import MeetingStartDevicesSelection from './components/MettingStartDevicesSelection/MeetingStartDevicesSelection'
import useRoomState from './hooks/useRoomState/useRoomState';
import RatingModal from './components/RatingModal/RatingModal';
import RejoinMeeting from './components/RejoinMeeting/RejoinMeeting';
import App from './App';
import { useAppState } from './state';

export default function VideoProviderRouter () {
    const roomState = useRoomState();
    const { shouldShowRatingModal, shouldShowRejoinMeeting, shouldShowDeviceSelection, isMeetingRefresh } = useAppState();
  
     if(roomState === 'reconnecting' || isMeetingRefresh)
    {
      return <></>
    }
  
    if (roomState === 'connected') {
      return <App />;
    }
  
    if (shouldShowDeviceSelection) {
      return <MeetingStartDevicesSelection />;
    }
  
    if (shouldShowRatingModal && !shouldShowRejoinMeeting) {
      return <RatingModal />;
    }
  
    if(shouldShowRejoinMeeting){
      return <RejoinMeeting/>
    }
  
    return <></>
  }