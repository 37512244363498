import React from 'react';
import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import { useAppState } from './state';
import { VideoProvider } from './components/VideoProvider';
import EntryScreen from './components/EntryScreen/EntryScreen';
import BrowserPermissionCheck from './components/BrowserPermissionCheck/BrowserPermissionCheck';
import VideoProviderRouter from './VideoProviderRouter';

export default function VideoApp () {
    const { isValidMeetingCode, hasMediaDevicesAccess, settings, error, setError } = useAppState();
  
    if (!isValidMeetingCode) {
      return <EntryScreen />
    }
  
    if (isValidMeetingCode && !hasMediaDevicesAccess) {
      return <BrowserPermissionCheck />
    }
  
    const connectionOptions = generateConnectionOptions(settings);
    return (
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <VideoProviderRouter />
      </VideoProvider>
    );
  };