import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import useRatingModal from '../../hooks/useRatingModal/useRatingModal';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../theme';
import { useAppState } from '../../state';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    rating: {
        marginBottom: theme.spacing(4),
    },
    error: {
        color: '#f44336',
        marginTop: '16px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

export default function RatingModal() {
    const { error, hide, leaveFeedback, submitting } = useRatingModal();
    const [rating, setRating] = useState<number | null>(null);
    const [comment, setComment] = useState('');
    const classes = useStyles();
    const { setShouldShowRejoinMeeting, setShouldShowRatingModal } = useAppState();

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const handleLeaveFeedback = () => {
        leaveFeedback(rating, comment);
        // onClose();
    };

    function handleHide() {
        hide();
        onClose();
    }

    const onClose = () => {
        setShouldShowRatingModal(false);
        setShouldShowRejoinMeeting(true);
    }

    return (
        <Dialog
            // open={canShow && shouldShow}
            open={true}
            onClose={handleHide}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle>Your Session has Ended
                <IconButton edge="start" className={classes.closeButton} onClick={() => handleHide()} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Rate your experience with the virtual clinic
                </DialogContentText>
                <div className={classes.container}>
                    <Rating
                        name="rating"
                        size="large"
                        value={rating}
                        onChange={(event, newValue) => setRating(newValue)}
                        className={classes.rating}
                    />
                    <TextField
                        disabled={rating === null}
                        label="Describe your experience"
                        multiline
                        rows={4}
                        variant="outlined"
                        onChange={handleCommentChange}
                    />
                    <div className={classes.error}>{error}</div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleLeaveFeedback}
                    variant="contained"
                    color="primary"
                    autoFocus
                    disabled={rating === null || submitting}
                >
                    {submitting ? 'SUBMITTING...' : 'SUBMIT'}
                </Button>
                <Button
                    onClick={handleHide}
                >
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}