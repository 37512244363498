import React, { useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import Controls from './components/Controls/Controls';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import { useAppState } from './state';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const { setShouldShowRatingModal, setShouldShowRejoinMeeting } = useAppState();

  useEffect(() => {
    return function changeState() {
      if (roomState === 'connected') {
        setShouldShowRatingModal(true);
        setShouldShowRejoinMeeting(false);
      }
    }
  });

  //withAITracking(reactPlugin, Container);

  return (
      <Container style={{ height }}>
        <MenuBar />
        <Main>
          <Room />
          <Controls />
        </Main>
        <ReconnectingNotification />
      </Container>
  );
}
