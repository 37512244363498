import React, { useEffect } from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { useDevices } from '../deviceHooks/deviceHooks';
import VideoTrack from '../../../VideoTrack/VideoTrack';

const useStyles = makeStyles({
  preview: {
    width: '150px',
    margin: '0.5em 0',
  },
  container: {
    width: '100%',
  }
});

export default function VideoInputList() {
  const classes = useStyles();
  const devices = useDevices();
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  let localVideoInputDeviceId = localVideoTrack?.mediaStreamTrack?.getSettings()?.deviceId;
  const videoInputDevices = devices.length ? devices.filter(device => device.kind === 'videoinput') : [];

  useEffect(() => {
    if (!videoInputDevices.length) {
      return;
    }
    // Hack to force default front camera
    const frontVideoDevice = videoInputDevices.find(device => device.label.toLowerCase().includes('front'));

    if (!localVideoInputDeviceId) {
      // If local video is not preselected, and video devices exist,
      // select the front camera or first video device manually
      const deviceIdToSelect = frontVideoDevice?.deviceId || videoInputDevices[0].deviceId;
      replaceTrack(deviceIdToSelect);
      return;
    }

    // If local video was preselected, but front video device exists and was not selected
    // select front device
    if (frontVideoDevice && localVideoInputDeviceId !== frontVideoDevice?.deviceId) {
      replaceTrack(frontVideoDevice.deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  function replaceTrack(newDeviceId: string) {
    localVideoTrack?.stop();
    getLocalVideoTrack({ deviceId: { exact: newDeviceId } }).then(newTrack => {
      if (localVideoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(localVideoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
      }

      localParticipant?.publishTrack(newTrack);
    });
  }

  return (
    <div className="inputSelect">
      {videoInputDevices.length > 1 ? (
        <FormControl className={classes.container}>
          <Typography variant="h6">Camera</Typography>
          <Select
            value={localVideoInputDeviceId || ''}
            onChange={e => replaceTrack(e.target.value as string)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select camera
            </MenuItem>
            {videoInputDevices.map(device => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
          <>
            <Typography variant="h6">Camera</Typography>
            <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
          </>
        )}
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
    </div>
  );
}
