import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import useParticipants from '../../hooks/useParticipants/useParticipants';

import { useAppState } from '../../state';
import { newRowForMobile } from '../../utils';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Menu from './Menu/Menu';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import ReloadOption from './ReloadOption/ReloadOption';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      flexWrap: 'wrap',
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
      },
      waitMessage: {
          textAlign: 'center',
          flexGrow: 1,
          paddingLeft: '25px',
          paddingRight: '25px',
    },
    roomNameContainer: {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      marginLeft: newRowForMobile() ? '0em' : '1em',
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { URLMeetingCode } = useParams() as {
    URLMeetingCode: string;
  };
  const { getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();
  const history = useHistory();
  const participants = useParticipants();

  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if (URLMeetingCode) {
      setRoomName(URLMeetingCode);
    }
    // eslint-disable-next-line    
  }, [URLMeetingCode]);

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (URLMeetingCode && URLMeetingCode === roomName) {
      getToken(URLMeetingCode).then(token => connect(token));
    } else {
      history.push(`/meeting/${roomName}`);
    }
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              id="menu-room"
              label="Meeting Code"
              className={classes.textField}
              value={roomName}
              onChange={handleRoomNameChange}
              margin="dense"
            />
            <Button
              id="join-meeting-btn"
              className={classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              disabled={isAcquiringLocalTracks || isConnecting || !roomName || isFetching}
            >
              Join Meeting
            </Button>
            {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
          </form>
        ) : (
          <div className={classes.roomNameContainer}>
            <h3 id="room-name">{roomName}</h3>
            {newRowForMobile() ? undefined : <ReloadOption />}    
          </div>)}
              {participants.length === 0 ? <h3 className={classes.waitMessage}>You're connected to the meeting. Please wait for others to join. Keep your camera and microphone on.</h3> : undefined}
        <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu />
        </div>
            {newRowForMobile() ? <ReloadOption /> : undefined}
      </Toolbar>
    </AppBar>
  );
}
