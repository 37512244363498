import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { newRowForMobile } from '../../../utils';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: 'rgba(255,255,255,0.7)',
      marginLeft: newRowForMobile() ? '0em':'1em',
      marginBottom: newRowForMobile() ? '1em' : '0em',
    },
    button: {
      cursor: 'pointer',
      marginLeft: '0.2em',
      textDecoration: 'underline',
      color: '#fff',
    },
  })
);

export default function ReloadOption() {
    const classes = useStyles();
    const { setIsMeetingRefresh } = useAppState();
    const handleReload = () => {
        setIsMeetingRefresh(true);
        window.location.reload();
    }

    return (
        <div className={classes.container}>
            Audio/Video problems? Refresh
            <span className={classes.button} onClick={handleReload}>here.</span>
        </div>
    );
}
