import { useEffect, useState } from 'react';
import useRoomState from '../useRoomState/useRoomState';
import { useParams } from 'react-router-dom';
import { useAppState } from '../../state';

export default function useRatingModal() {
    const [canShow, setCanShow] = useState(false);
    const [shouldShow, setShouldShow] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const { setShouldShowRejoinMeeting, setShouldShowRatingModal } = useAppState();

    const { URLMeetingCode } = useParams() as {
        URLMeetingCode: string;
    };
    const roomState = useRoomState();

    async function postFeedback(data = {}) {
        const endpoint = process.env.REACT_APP_FEEDBACK_ENDPOINT;
        const url = `${endpoint}?meetingCode=${URLMeetingCode}`;
        setSubmitting(true);
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    }

    function hide() {
        setCanShow(false);
        setShouldShow(false);
        setError(null);
    }

    function leaveFeedback(rating: number | null, comment: string) {
        postFeedback({ rating, comments: comment })
            .then(res => {
                setSubmitting(false);
                if (res.ok) {
                    setShouldShowRatingModal(false);
                    setShouldShowRejoinMeeting(true);
                } else {
                    // TODO: Show snackbar on error
                    setError('Unable to submit feedback');
                }
                return res.text();
            })
            .catch(err => {
                console.log('error', err);
            });
    }

    useEffect(() => {
        if (roomState === 'connected') {
            setCanShow(true);
        } else if (roomState === 'disconnected' && canShow) {
            setShouldShow(true);
        }
    }, [roomState, canShow, shouldShow]);

    return {
        canShow,
        shouldShow,
        error,
        hide,
        leaveFeedback,
        submitting,
    }
}