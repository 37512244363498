import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function NotSupported() {
  const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.messageheader}>
                <ErrorOutlineIcon className={classes.icon} />
                <h2>Your browser is not supported</h2>
            </div>
            <p className={classes.message}>iPhone users must use Safari as their browser to access the video session. Android users and Windows users may use Chrome or Firefox as their browser.
              </p>
        </div>
    );
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '16px'
    },
    messageheadercontainer: {
        display: 'flex'
    },
    messageheader: {
        display: 'flex',
        alignItems: 'center'
    },
    message: {
        textAlign: 'center'
    },
    icon: {
        marginRight: '16px',
        fontSize: '32px',
    },
});
