import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import AppStateProvider from './state';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import NotSupported from './components/NotSupported/NotSupported';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';

import VideoApp from './VideoApp';
import DetectRTC from 'detectrtc';

import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";


const BaseVideoApp = () => {
  return (
    <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/meeting/:URLMeetingCode">
              <VideoApp />
            </PrivateRoute>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
    </AppInsightsErrorBoundary>);
}

// if twilio-video is supported, render app,
// else render not supported info
if (navigator.mediaDevices && DetectRTC.isWebRTCSupported) {
  ReactDOM.render(
    <AppInsightsContext.Provider value={reactPlugin}>
      <BaseVideoApp></BaseVideoApp>
    </AppInsightsContext.Provider>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <NotSupported />
    </MuiThemeProvider>,
    document.getElementById('root')
  );

}

