import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ensureMediaPermissions, canAccessMedia } from '../../utils';
import { useAppState } from '../../state/index'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
    }));


export default function BrowserPermissionCheck() {
    const { setHasMediaDevicesAccess } = useAppState();
    const [accessCheckCompleted, setAccessCheckCompleted] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        return () => {
            canAccessMedia()
                .then(res => {
                    setHasMediaDevicesAccess(res);

                    if (!res) {
                        setAccessCheckCompleted(true);
                        ensureMediaPermissions();
                    }
                })
                .catch(reason => {
                    setAccessCheckCompleted(true);
                });
        }
    });

    if (accessCheckCompleted) {
        return (
            <Box className={classes.container} m={4}>
                <Typography variant="h5" gutterBottom>
                    Please Allow Device Access
                </Typography>
                <Typography color="textSecondary" variant="subtitle1">
                    Oops! We are unable to access your microphone or camera. Please click "allow" so we can launch your video session.
                </Typography>
            </Box>);
    }
    return (
    <Box className={classes.container} m={4}>
        <Typography variant="h5" gutterBottom>Checking camera and microphone access...</Typography>
        <Typography color="textSecondary" variant="subtitle1">Please allow access if asked</Typography>
    </Box>);
}